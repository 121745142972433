<template>
	<!-- <el-dialog :title="$t('i18nn_785ee8efb6d09b2a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="800px"
		top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_58213be577a36611')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="600px">
		<div class="">

			<el-tabs type="border-card" v-model="activeName" v-loading="loading_load">
				<el-tab-pane label="" name="first">
					<div slot="label">{{$t('i18nn_db2c3e5fbf7e8716')}}<el-badge :value="tableData.length" type="warning"></el-badge>
					</div>

					<el-table ref="multipleTable" :data="tableData" stripe :border="true"
						:height="$store.state.tableMaxHeight" style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
						<el-table-column prop="tracking" :label="$t('i18nn_4531476fa35570f0')"></el-table-column>
						<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column>
						<el-table-column prop="printLabel" :label="$t('i18nn_8b7f3722921430ec')"></el-table-column>
						 
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="" name="second">
					<div slot="label">{{$t('i18nn_4930700ae08b8ce9')}}<el-badge :value="tableData2.length" type="danger"></el-badge>
					</div>
					<el-table ref="multipleTable2" :data="tableData2" stripe :border="true"
						:height="$store.state.tableMaxHeight" style="width: 100%" size="small">
						<el-table-column type="index" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>
						<el-table-column prop="tracking" :label="$t('i18nn_4531476fa35570f0')"></el-table-column>
						<el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column>
						<el-table-column prop="printLabel" :label="$t('i18nn_8b7f3722921430ec')"></el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>

		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('4e9fc68608c60999') }}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			// row: {},
			outLpn: {},
			batchNo: {}
		},
		components: {

		},
		data() {
			return {
				dialogFile: false,
				// multipleSelection: [],
				// expands:[],

				loading_load: false,

				loading: false,
				//表格数据
				tableData: [],
				tableData2: [],
				activeName: 'first',
				// //分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 300],
				// 	page_size: 200,
				// 	total: 0
				// },
				// formData:{
				// 	plCount:10,
				// },

				// selectOption: {
				// 	wh_no: [],
				// },
				//查询，排序方式
				// filterData: {

				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			// console.log('WhDropShipping activated');

		},
		//创建时
		created() {
			// console.log('WhDropShipping created');
		},
		//编译挂载前
		mounted() {},
		methods: {
			goBack() {

			},

			initData() {
				this.tableData = [];
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				// let _this = this;

				let filterData = {
					batchNo: this.batchNo?this.batchNo:null,
					outLpn: this.outLpn?this.outLpn:null,
				};

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhDsScanPrintDet, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code && data.data) {
							//表格显示数据
							if (data.data.printed) {
								this.tableData = data.data.printed;
							}
							if (data.data.noPrint) {
								this.tableData2 = data.data.noPrint;
							}
							//当前数据总条数
							// this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('商家账单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},
			//选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	// this.multipleSelection = val;
			// },

			//自动生成批次
			// toAutoCreateBatch(){
			// 	if (!this.filterData.userId) {
			// 		this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 		return;
			// 	}
			// 	// this.$confirm("确定按当前搜索条件，自动生成批次吗？", this.$t('daaaeb1b7b22b126'), {
			// 	// 		type: 'warning',
			// 	// 	})
			// 	// 	.then(() => {
			// 			this.AutoCreateBatchData();
			// 	// 	})
			// 	// 	.catch(() => {

			// 	// 	});
			// },

			// //创建批次提交
			// AutoCreateBatchData() {
			// 	let filterData = Object.assign({},
			// 		this.filterData
			// 	);
			// 	filterData.plCount = this.formData.plCount;

			// 	this.postData(this.$urlConfig.WhSendExpressAutoCreateBatch + '/' + filterData.userId, filterData, res => {
			// 		if (200 == res.code) {
			// 			// this.initData();
			// 			this.dialogFile = false;
			// 			this.$message.success('生成成功！');

			// 			this.$emit("success")
			// 		}

			// 		// this.$router.push({name:'WhPackage',query:{open:'add'}});
			// 	});
			// },

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_carrier_code', 'wh_signature_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				this.selectOption.wh_carrier_code = data.data['wh_carrier_code'];
			// 				this.selectOption.wh_signature_service = data.data['wh_signature_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
